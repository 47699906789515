import React, { useState } from 'react'
import { Button, Seo, Heading, Spacer } from '../components'
import { GlobalStyles } from '../misc/globalStyles'
import { COLORS, HEADING_LEVELS, OFFSETS, ROUTES } from '../misc/constants'
import { BUTTON_VARIANTS } from '../components/Button/Button'
import { TextPagesWrapper } from '../components/layoutComponents'

const CONSENTS_DATA = {
  MARKETING: {
    HEADING: {
      PL: `Zgoda na otrzymywanie informacji handlowej`,
      ENG: `Consent to receive commercial information`
    },
    TEXT: {
      PL: `Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany przeze mnie w formularzu zgłoszeniowym adres e-mail informacji handlowych w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. 2002 nr 144 poz. 1204), dotyczących produktów i usług oferowanych przez NANOLABS Spółkę z ograniczoną odpowiedzialnością spółkę komandytową z siedzibą w Poznaniu, przy ul. 27 grudnia 3, 61-737 Poznań, NIP: 783- 177-13-47, REGON: 36934677, KRS: 0000716046 i oświadczam, iż mam świadomość, że wyrażenie przeze mnie zgody jest dobrowolne.`,
      ENG: `ENG TEXT`
    },
    REGULATIONS: [
    {
      PL: `Administratorem Twoich danych osobowych jest: NANOLABS Spółka z ograniczoną odpowiedzialnością spółka komandytowa z siedzibą w Poznaniu, przy ul. 27 grudnia 3, 61-737 Poznań, NIP: 783-177-13-47, REGON: 36934677, KRS: 0000716046, E-mail: gdpr@venturedevs.com.`,
      ENG: `ENG TEXT`
    },
    {
      PL: `Twoje dane osobowe będziemy przetwarzać:`,
      ENG: `ENG TEXT`,
      SUBITEM: {
        PL: `w celu organizacji oraz relacjonowania przebiegu #VentureMeetups (włącznie z utrwaleniem i wykorzystaniem Twojego wizerunku) - na podstawie Twojej zgody (art. 6 ust. 1 lit. a) RODO; b) w celu wysyłania na podany przez Ciebie w formularzu zgłoszeniowym adres e-mail informacji handlowych, na podstawie Twojej zgody na otrzymywanie drogą elektroniczną na wskazany w formularzu aplikacyjnym adres e-mail informacji handlowych (art. 6 ust. 1 lit. a) RODO)`,
        ENG: `ENG TEXT`
      }
    },
    {
      PL: `Odbiorcami Twoich danych osobowych będą podmioty współpracujące z Administratorem w celu zorganizowania i przeprowadzenia #VentureMeetups.`,
      ENG: `ENG TEXT`
    },
    {
      PL: `Twoje dane osobowe będą przechowywane przez Administratora do momentu wycofania przez Ciebie zgody na przetwarzanie danych.`,
      ENG: `ENG TEXT`
    },
    {
      PL: `Posiadasz prawo żądania dostępu do swoich danych, prawo żądania ich sprostowania, usunięcia i ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec przetwarzania danych.`,
      ENG: `ENG TEXT`
    },
    {
      PL: `Możesz w każdej chwili cofnąć zgodę na przetwarzanie danych poprzez środki komunikacji elektronicznej - tj. email na adres: gdpr@venturedevs.com lub metodą korespondencyjną tradycyjną (poczta) na adres Administratora (pkt 1) - z zastrzeżeniem, że wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania danych osobowych w okresie przed jej wycofaniem.`,
      ENG: `ENG TEXT`
    },
    {
      PL: `Przysługuje Ci prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeśli uznasz, że przetwarzanie Twoich danych osobowych narusza przepisy dotyczące ochrony danych osobowych.`,
      ENG: `ENG TEXT`
    },
    {
      PL: `W oparciu o Twoje dane osobowe nie będziemy podejmować zautomatyzowanych decyzji, w tym decyzji będących wynikiem profilowania.`,
      ENG: `ENG TEXT`
    },
  ]},
  DATA_PROCESSING: {
    HEADING: {
      PL: `Zgoda na przetwarzanie danych osobowych`,
      ENG: `Consent to the processing of personal data`
    },
    TEXT: {
      PL: `Wysyłając powyższe zgłoszenie na wydarzenie #VentureMeetups do NANOLABS Spółki z ograniczoną odpowiedzialnością spółki komandytowej z siedzibą w Poznaniu, przy ul. 27 grudnia 3, 61-737 Poznań, NIP: 783-177-13-47 , REGON: 36934677, zarejestrowanej w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy Poznań Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0000716046 organizatora #VentureMeetups. - zgadzam się na przetwarzanie przez Spółkę moich danych osobowych zawartych w zgłoszeniu takich jak: imię i nazwisko, adres email na cele związane z moim udziałem w #VentureMeetups.`,
      ENG: `ENG TEXT`
    }
  }
}

export default function Consents() {
  const [isPl, setIsPl] = useState(true)

  function handleToggleLanguage() {
    setIsPl(previousValue => !previousValue)
  }

  return (
    <TextPagesWrapper>
      <Seo title="#VentureMeetups | Consents" />

      <GlobalStyles />

      <Button
        onClick={handleToggleLanguage}
        variant={BUTTON_VARIANTS.SECONDARY}
      >
        {
          isPl
            ? 'View in English'
            : 'Wyświetl po polsku'
        }
      </Button>

      <Spacer height={OFFSETS[48]} />

      <Heading
        level={HEADING_LEVELS.H1}
        color={COLORS.WHITE}
      >
        {
          isPl
            ? CONSENTS_DATA.DATA_PROCESSING.HEADING.PL
            : CONSENTS_DATA.DATA_PROCESSING.HEADING.ENG
        }
      </Heading>
      
      <p>
        {
          isPl
            ? CONSENTS_DATA.DATA_PROCESSING.TEXT.PL
            : CONSENTS_DATA.DATA_PROCESSING.TEXT.ENG
        }
      </p>

      <br />
      <br />

      <Heading
        level={HEADING_LEVELS.H1}
        color={COLORS.WHITE}
      >
        {
          isPl
            ? CONSENTS_DATA.MARKETING.HEADING.PL
            : CONSENTS_DATA.MARKETING.HEADING.ENG
        }
      </Heading>

      <p>
        {
          isPl
            ? CONSENTS_DATA.MARKETING.TEXT.PL
            : CONSENTS_DATA.MARKETING.TEXT.ENG
        }
      </p>
      
      <ol>
        {CONSENTS_DATA.MARKETING.REGULATIONS.map((item, idx) => (
          <li key={item.PL}>
            {isPl ? item.PL : item.ENG}
            {
              item.SUBITEM
                && (
                  <ol key={idx}>
                    <li>
                      {isPl ? item.SUBITEM.PL : item.SUBITEM.ENG}
                    </li>
                  </ol>
                )
            }
          </li>
        ))}
      </ol>

      <Spacer height={OFFSETS[48]} />

      <Button
        isLink
        url={ROUTES.HOME}
      >
        {
          isPl
            ? 'Wróć do strony głównej'
            : 'Go back home'
        }
      </Button>
    </TextPagesWrapper>
  )
}
